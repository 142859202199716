<template>
  <div class="myWallet">
    <rxNavBar title="零钱明细"></rxNavBar>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="noContent ? '' : '已经到底了'"
          @load="this.walleyInit"
      >
        <div class="Wallet">
          <div class="detail" v-for="item in userMoneyFlowList" :key="item.id">
            <div class="detail_border">
              <div>
                <div class="detail_message">{{ item.flowRemark }}</div>
                <div class="detail_titme">{{ item.flowTime }}</div>
              </div>
              <div class="detail_money" :class="{'orangeColor':item.flowMoney>0}">
                {{ item.flowMoney }}
              </div>
            </div>
          </div>
        </div>
        <common-empty v-if="noContent"></common-empty>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import {NavBar, Icon, Cell, CellGroup, Button, Col, Row, List, PullRefresh} from 'vant';
import {getStaffId, globaluserId, responseUtil} from "../../../libs/rongxunUtil";
import {userMoneyFlow} from "../../../getData/getData";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";

export default {
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Col.name]: Col,
    [Row.name]: Row,
    [List.name]: List,
    [Icon.name]: Icon,
    [PullRefresh.name]: PullRefresh,
    rxNavBar
  },

  data() {
    return {
      //上拉加载下拉刷新 start
      page: 1,
      loading: false,
      finished: false,
      count: 0,
      refreshing: false,
      //上拉加载下拉刷新 end
      flag: false,
      noContent: false,

      userMoneyFlowList: [],
    }
  },
  name: "myWallet",

  methods: {
    //返回上一层
    leftReturn() {
      this.$router.go(-1);
    },
    //初始化
    walleyInit() {
      let that = this

      if (that.refreshing) {
        that.list = [];
        that.refreshing = false;
      }
      let walletInitData = {}
      // walletInitData.user_id= getStaffId()
      walletInitData.user_id = globaluserId()
      walletInitData.currentPage = that.page + ''
      walletInitData.numberPage = "10"
      userMoneyFlow(walletInitData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response)
          that.count = response.data.data.userMoneyFlowList.rowCount
          console.log("count" + that.count)
          let list = response.data.data.userMoneyFlowList.result
          if (list)
            for (let i = 0; i < list.length; i++) {
              that.userMoneyFlowList.push(list[i])
            }

          // 加载状态结束
          that.loading = false;

          // 数据全部加载完成
          if (that.userMoneyFlowList.length >= that.count) {
            that.finished = true;
            console.log(that.finished)
          }

          if (1 == that.page && (!list || 0 == list.length)) {
            that.noContent = true
          } else {
            that.noContent = false
          }
          that.page++;
        })
      })
    },

    onRefresh() {
      let that = this;

      // 清空列表数据
      that.userMoneyFlowList = [];
      that.page = 1;

      that.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      that.loading = true;
      this.noContent = false
      that.walleyInit();
    }
  },

}
</script>

<style scoped>
.orangeColor {
  color: #FF5D3B;
}

.Wallet {
  background-color: #FFFFFF;
  width: 375px;

}

.border {
  display: flex;
}

.detail_border {
  display: flex;
  border-bottom: 1px solid #EFEFEF;

}

.detail {
  padding: 10px 15px 0px 15px;
  width: 345px;
}

.detail_message {
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  width: 154px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail_titme {
  padding-top: 5px;
  padding-bottom: 10px;
  font-size: 13px;
  font-weight: normal;
  color: #999999;
}

.detail_money {
  flex: 1;
  text-align: right;
  font-size: 15px;
  font-weight: bold;
}

.noMessage {
  font-size: 13px;
  font-weight: normal;
  color: #999999;
  text-align: center;
  padding-top: 20px;
  margin-top: -1px;
  background-color: #f7f7f7;
}

</style>
